























import { Component, Vue } from "nuxt-property-decorator";
import Elevator from "elevator.js";

@Component<OElevator>({})
export default class OElevator extends Vue {
  elevator: any = null;

  scrolledTop = false;
  visible = false;
  locked = false;

  lastScroll = 0;

  mounted() {
    this.elevator = new Elevator({
      mainAudio: "/sfx/elevator.mp3",
      endAudio: "/sfx/ding.mp3",
      endCallback: () => {
        this.locked = false;

        if ("vibrate" in navigator) {
          navigator.vibrate(100);
        }

        window.removeEventListener("blur", this.handleBlur);
      }
    });

    window.addEventListener("scroll", this.handleScroll);
  }

  elevate() {
    this.locked = true;
    this.elevator.elevate();

    this.$ga.event({
      eventCategory: "Elevator",
      eventAction: "Up",
      eventLabel: "Winda jedzie w górę"
    });

    if ("vibrate" in navigator) {
      navigator.vibrate(100);
    }

    window.addEventListener("blur", this.handleBlur);
  }

  handleScroll() {
    this.scrolledTop = window.scrollY < this.lastScroll;
    this.lastScroll = window.scrollY;
    this.visible = window.scrollY > window.innerHeight;
  }

  handleBlur() {
    this.locked = false;
  }
}
