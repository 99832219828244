






import { Component, Vue, Prop } from 'nuxt-property-decorator'

@Component<AButton>({})
export default class AButton extends Vue {
  @Prop({ default: 'nuxt-link' }) buttonType!: 'nuxt-link' | 'link' | 'button' 
  @Prop({ default: 'default' }) buttonStyle!: 'default' | 'invert' | 'white'
  @Prop({ default: false }) iconOnly!: boolean
  @Prop({ default: false }) smallIcon!: boolean

  get buttonClass() {
    return {
      'app-button--invert': this.buttonStyle === 'invert',
      'app-button--white': this.buttonStyle === 'white',
      'app-button--icon': !!this.iconOnly,
      'app-button--icon-small': !!this.smallIcon
    }
  }
}
