










import { Component, Vue } from 'nuxt-property-decorator'

@Component<OError500>({})
export default class OError500 extends Vue {}
