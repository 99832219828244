




















import { Component, Vue, Prop } from 'nuxt-property-decorator'

@Component<ANavbarHamburgerMenuItem>({})
export default class ANavbarHamburgerMenuItem extends Vue {
  @Prop({ default: null }) item!: {
    to: string;
    label: string;
  }
}
