



























import { Component, Vue, Watch } from 'nuxt-property-decorator'

@Component<AppShowreelModal>({})
export default class AppShowreelModal extends Vue {
  playerVars = { playlist: this.videoId, autoplay: 1, modestbranding: 1, autohide: 1, controls: 0, showinfo: 0, rel: 0 }

  video: any = null
  ambilight: any = null

  videoReady(event: any) {
    this.video = event.target
    this.video.setVolume(100)
  }

  videoStateChange(event: any) {
    switch (event.data) {
      case 1:
        this.ambilight.seekTo(event.target.getCurrentTime());
        this.ambilight.playVideo();
        break;
      case 2:
        this.ambilight.seekTo(event.target.getCurrentTime());
        this.ambilight.pauseVideo();
        break;
    }
  }

  optimizeAmbilight(event: any) {
    const qualityLevels = event.target.getAvailableQualityLevels();
    event.target.mute();
    if (qualityLevels && qualityLevels.length && qualityLevels.length > 0) {
      qualityLevels.reverse();
      const lowestLevel = qualityLevels[qualityLevels.findIndex((q: any) => q !== 'auto')];
      event.target.setPlaybackQuality(lowestLevel);
    }
  }

  ambilightReady(event: any) {
    this.ambilight = event.target
    this.ambilight.mute();
    this.optimizeAmbilight(event);
    requestAnimationFrame(this.step);
  }

  ambilightStateChange(event: any) {
    switch (event.data) {
      case 3:
      case 1:
        this.optimizeAmbilight(event)
        break
    }
  }

  step() {
    if (this.ambilight && this.video) this.ambilight.seekTo(this.video.getCurrentTime())
    requestAnimationFrame(this.step)
  }


  get videoId() {
    return this.$store.state.showreel.videoId
  }

  get visible() {
    return this.$store.state.showreel.visible
  }

  hide() {
    this.$store.dispatch('showreel/hide')
  }

  keys: { [key: number]: number } = { 37: 1, 38: 1, 39: 1, 40: 1 };

  onScroll(e: Event) {
    e.preventDefault();
  }

  onScrollKey(e: KeyboardEvent) {
    if (this.keys[e.keyCode]) {
      this.onScroll(e);
      return false;
    }
  }

  supportsPassive = false;
  wheelEvent = 'mousewheel';
  wheelOpt: any = false

  mounted() {
    try {
      window.addEventListener("test", () => [], Object.defineProperty({}, 'passive', {
        get: () => { this.supportsPassive = true; } 
      }));
    } catch(e) {}

    this.wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel'
    this.wheelOpt = this.supportsPassive ? { passive: false } : false;
  }

  disableScroll() {
    window.addEventListener('DOMMouseScroll', this.onScroll, false)
    window.addEventListener(this.wheelEvent, this.onScroll, this.wheelOpt)
    window.addEventListener('touchmove', this.onScroll, this.wheelOpt)
    window.addEventListener('keydown', this.onScrollKey, false)
  }

  enableScroll() {
    window.removeEventListener('DOMMouseScroll', this.onScroll, false);
    window.removeEventListener(this.wheelEvent, this.onScroll, this.wheelOpt); 
    window.removeEventListener('touchmove', this.onScroll, this.wheelOpt);
    window.removeEventListener('keydown', this.onScrollKey, false);
  }

  @Watch('visible')
  onVisibleChange(value: boolean) {
    if (value) this.disableScroll()
    else this.enableScroll()
  }
}
