


















import { Component, Vue } from 'nuxt-property-decorator'

@Component<ANavbarLogo>({})
export default class ANavbarLogo extends Vue {}
