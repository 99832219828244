import { render, staticRenderFns } from "./error.vue?vue&type=template&id=79d8fb58&lang=pug&"
import script from "./error.vue?vue&type=script&lang=ts&"
export * from "./error.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Error400: require('/var/www/imoli.app/components/Error/Error400.vue').default,Error500: require('/var/www/imoli.app/components/Error/Error500.vue').default,AppFooter: require('/var/www/imoli.app/components/App/AppFooter/AppFooter.vue').default})
