import { MutationTree, ActionTree, GetterTree } from 'vuex'
import { State as RootState } from '.'

export interface State {
  visible: boolean;
  videoId: string;
}

export const state = (): State => ({
  visible: false,
  videoId: 'aqz-KE-bpKQ'
})

export const mutations: MutationTree<State> = {
  setVisible: (state: State, value: boolean) => (state.visible = value)
}

export const getters: GetterTree<State, RootState> = {
  isVisible: (state: State) => state.visible
}

export const actions: ActionTree<State, RootState> = {
  show: ({ commit }) => commit('setVisible', true),
  hide: ({ commit }) => commit('setVisible', false)
}
