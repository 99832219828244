







































import { Component, Vue } from 'nuxt-property-decorator'

@Component<MFooterContentDecoration>({})
export default class MFooterContentDecoration extends Vue {}
