



























import { Component, Vue } from 'nuxt-property-decorator'

@Component<OError404>({})
export default class OError404 extends Vue {}
