










import { Component, Vue, Watch } from "nuxt-property-decorator";

@Component<DefaultLayout>({
  head() {
    const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true });
    return {
      titleTemplate: t =>
        t != this.$tc("opengraph.title")
          ? `${t} • ${this.$tc("opengraph.title")}`
          : this.$tc("opengraph.title"),
      title: this.$tc("opengraph.title"),
      htmlAttrs: {
        ...i18nHead.htmlAttrs
      },
      meta: [
        {
          hid: "description",
          name: "description",
          content: this.$tc("opengraph.description")
        },
        {
          hid: "og:image",
          property: "og:image",
          content:
            "https://imoli.dev" + require("@/assets/img/opengraph/banner.png")
        },
        {
          hid: "og:site_name",
          property: "og:site_name",
          content: this.$tc("opengraph.title")
        },
        {
          hid: "og:title",
          property: "og:title",
          content: this.$tc("opengraph.title")
        },
        {
          hid: "og:description",
          property: "og:description",
          content: this.$tc("opengraph.description")
        },
        ...i18nHead.meta
      ],
      link: [...i18nHead.link],
      script: [
        {
          hid: "tawk.to",
          src:
            this.$i18n.locale === "pl"
              ? "https://embed.tawk.to/57b626a5a767d83b45e4122a/default" // Polish
              : this.$i18n.locale === "no"
              ? "https://embed.tawk.to/57b626a5a767d83b45e4122a/1h3hclsi2" // Norwegian
              : "https://embed.tawk.to/57b626a5a767d83b45e4122a/1fpjs1mtr", // Global
          async: true,
          defer: true,
          charset: "UTF-8",
          crossOrigin: "*"
        }
      ]
    };
  }
})
export default class DefaultLayout extends Vue {
  get mode() {
    return this.$store.getters["theme/getMode"];
  }

  @Watch("mode", { immediate: true, deep: false })
  onModeChange(newMode: "light" | "dark") {
    if (process.client) {
      document.body.classList.remove("theme--dark");

      if (newMode === "dark") {
        document.body.classList.add("theme--dark");
      }
    }
  }

  get jsonldOrganization() {
    return {
      "@context": "https://schema.org",
      "@type": "Organization",
      name: "Imoli",
      alternateName: "Agencja interaktywna Imoli",
      url: "https://imoli.dev",
      logo: "https://imoli.dev/logo.png",
      contactPoint: [
        {
          "@type": "ContactPoint",
          telephone: "+48 884 884 088",
          contactType: "customer service",
          areaServed: ["PL", "US", "GB", "CA"],
          availableLanguage: ["en", "Polish"]
        },
        {
          "@type": "ContactPoint",
          telephone: "+48 884 874 884",
          contactType: "technical support",
          areaServed: ["PL", "US", "GB", "CA"],
          availableLanguage: ["en", "Polish"]
        },
        {
          "@type": "ContactPoint",
          telephone: "+48 884 884 858",
          contactType: "sales",
          areaServed: ["PL", "US", "GB", "CA"],
          availableLanguage: ["en", "Polish"]
        }
      ]
    };
  }

  get jsonldBussiness() {
    return {
      "@context": "https://schema.org",
      "@type": "LocalBusiness",
      name: "Imoli",
      image: "https://imoli.dev/logo.png",
      "@id": "https://imoli.dev/#organization",
      url: "https://imoli.dev",
      telephone: "+48 884 884 088",
      priceRange: "$$$",
      address: {
        "@type": "PostalAddress",
        streetAddress: "Trawowa 1",
        addressLocality: "Częstochowa",
        postalCode: "42-280",
        addressCountry: "PL"
      },
      geo: {
        "@type": "GeoCoordinates",
        latitude: 50.7980329,
        longitude: 19.0580259
      },
      openingHoursSpecification: {
        "@type": "OpeningHoursSpecification",
        dayOfWeek: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
        opens: "08:00",
        closes: "16:00"
      },
      sameAs: [
        "https://www.facebook.com/imolipl/",
        "https://www.instagram.com/imoli_pl/",
        "https://www.linkedin.com/company/imoli/"
      ]
    };
  }

  offlineToast: any = null;

  @Watch("$nuxt.isOffline", { deep: false, immediate: true })
  onOffline(isOffline: boolean) {
    if (isOffline) {
      this.offlineToast = this.$toasted.show(
        this.$tc("toast.offline.content"),
        {
          type: "error",
          duration: 0,
          closeOnSwipe: false
        }
      );
    } else {
      if (this.offlineToast) {
        this.offlineToast.goAway(0);
      }
    }
  }

  mounted() {
    if (!this.$store.state.remember.cookiesAccepted) {
      this.$toasted.show(this.$tc("toast.cookies.content"), {
        duration: 0,
        closeOnSwipe: false,
        action: [
          {
            href: "#!",
            text: this.$tc("toast.cookies.action.what"),
            onClick: (e, toast) => {
              this.$router.push({
                path: this.localePath({ name: "privacy-policy" }),
                hash: "cookies"
              });
            }
          },
          {
            href: "#!",
            text: this.$tc("toast.cookies.action.ok"),
            class: "action-primary",
            onClick: (e, toast) => {
              toast.goAway(0);
              this.$store.commit("SET_COOKIES_ACCEPTED", true);
            }
          }
        ]
      });
    }

    if (!this.$store.state.remember.dontInstall) {
      window.addEventListener("beforeinstallprompt", (event: any) => {
        event.preventDefault();

        setTimeout(() => {
          this.$toasted.show(this.$tc("toast.install.content"), {
            duration: 0,
            closeOnSwipe: true,
            action: [
              {
                text: this.$tc("toast.install.action.no"),
                onClick: (e, toast) => {
                  toast.goAway(0);
                  this.$store.commit("SET_DONT_INSTALL", true);
                }
              },
              {
                text: this.$tc("toast.install.action.install"),
                class: "action-primary",
                onClick: (e, toast) => {
                  event.prompt();
                  toast.goAway(0);
                }
              }
            ]
          });
        });
      });
    }
  }
}
