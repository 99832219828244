

























import { Component, Vue } from "nuxt-property-decorator";

@Component<MNavbarHamburgerMenu>({})
export default class MNavbarHamburgerMenu extends Vue {
  onLanguageSwitch(lang: string) {
    if (lang) {
      this.$ga.event({
        eventCategory: "LanguageSwitch",
        eventAction: lang.toUpperCase(),
        eventLabel: `Zmieniono język na: ${lang.toUpperCase()}`
      });
    }
  }

  items = [
    {
      to: null,
      label: "navbar.menu.services", // Services
      children: [
        {
          to: null,
          label: "navbar.menu.software-house", // Software house
          children: [
            {
              to: { name: "services-web-development" },
              label: "navbar.menu.web-development" // Web development
            },
            {
              to: { name: "services-mobile-development" },
              label: "navbar.menu.mobile-development" // Mobile development
            },
            {
              to: { name: "services-product-design" },
              label: "navbar.menu.product-design" // Product design
            },
            {
              to: { name: "services-qa-and-testing" },
              label: "navbar.menu.qa-testing" // QA & testing
            },
            {
              to: { name: "services-outsourcing" },
              label: "navbar.menu.it-outsourcing" // IT outsourcing
            }
          ]
        },
        {
          to: null,
          label: "navbar.menu.interactive-agency", // Interactive agency
          children: [
            {
              to: { name: "services-branding" },
              label: "navbar.menu.branding" // Branding
            },
            {
              to: { name: "services-ui-ux-design" },
              label: "navbar.menu.ui-ux-design" // UI/UX design
            },
            {
              to: { name: "services-seo-sem-sxo" },
              label: "navbar.menu.seo-sem-sxo" // SEO, SEM, SXO
            },
            {
              to: { name: "services-marketing" },
              label: "navbar.menu.marketing" // Marketing
            },
            {
              to: { name: "services-social-media" },
              label: "navbar.menu.social-media" // Social media
            }
          ]
        }
      ]
    },
    {
      to: null,
      label: "navbar.menu.software", // Software
      children: [
        {
          to: { name: "software-e-commerce" },
          label: "navbar.menu.e-commerce" // E-commerce systems
        },
        {
          to: { name: "software-financial-and-accounting" },
          label: "navbar.menu.accounting" // Financial & accounting systems
        },
        {
          to: { name: "software-customer-relationship-management" },
          label: "navbar.menu.crm" // Customer relationship management
        },
        {
          to: { name: "software-enterprise-resource-planning" },
          label: "navbar.menu.erp" // Enterprise resource planning
        },
        {
          to: { name: "software-enterprise-application-integration" },
          label: "navbar.menu.eai" // Car eai management
        }
      ]
    },
    {
      to: { name: "portfolio" },
      label: "navbar.menu.portfolio" // Portfolio
    },
    {
      to: { name: "career" },
      label: "navbar.menu.career" // Career
    },
    {
      to: { name: "blog" },
      label: "navbar.menu.blog" // Blog
    },
    {
      to: { name: "about" },
      label: "navbar.menu.about" // About
    },
    {
      to: { name: "contact" },
      label: "navbar.menu.contact" // Contact
    }
  ];

  languages = ["pl", "en", "no", "de"];
}
