







import { Component, Vue } from 'nuxt-property-decorator'

@Component<MNavbarHamburger>({})
export default class MNavbarHamburger extends Vue {
  menu = false

  mounted() {
    this.$router.beforeEach((from, to, next) => {
      this.menu = false
      this.updateBody()

      next()
    })
  }

  toggle() {
    this.menu = !this.menu
    this.updateBody()
  }

  updateBody() {
    if (this.menu) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }
  }
}
