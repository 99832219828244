import { Context } from '@nuxt/types'

export default ({ app }: Context) => {
  // Every time the route changes (fired on initialization too)
  app.router.beforeEach((_to, _from, next) => {
    document.body.setAttribute("tabindex", "-1");
    document.body.focus();
    document.body.removeAttribute("tabindex");
    next();
  })
}
