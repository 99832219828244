














import { Component, Vue } from 'nuxt-property-decorator'

@Component<ONavbar>({})
export default class ONavbar extends Vue {
  scrollPos = 0

  get isStuck() {
    if (process.client) {
      return this.scrollPos > 100
    } else {
      return false
    }
  }

  mounted() {
    window.addEventListener('scroll', this.updateScrollPos)

    this.updateScrollPos()
  }

  updateScrollPos() {
    if (process.client) {
      this.scrollPos = window.scrollY
    }
  }
}
