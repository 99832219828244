







import { Component, Vue, Prop } from "nuxt-property-decorator";

@Component<ErrorPage>({})
export default class ErrorPage extends Vue {
  @Prop({ default: null }) error!: any;
}
