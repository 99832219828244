









import { Component, Vue } from 'nuxt-property-decorator'

@Component<ANavbarBrief>({})
export default class ANavbarBrief extends Vue {}
