












import { Component, Vue, Prop } from 'nuxt-property-decorator'

@Component<ANavbarLanguageItem>({})
export default class ANavbarLanguageItem extends Vue {
  @Prop({ default: null }) lang!: { code: string; name: string } | null;

  onLanguageSwitch() {
    if (this.lang) {
      this.$ga.event({
        eventCategory: 'LanguageSwitch',
        eventAction: this.lang.code.toUpperCase(),
        eventLabel: `Zmieniono język na: ${this.lang.code.toUpperCase()}`
      })
    }
  }
}
