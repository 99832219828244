















import Vue from "vue";
import { Component, Watch } from "nuxt-property-decorator";

@Component<ALightSwitch>({})
export default class ALightSwitch extends Vue {
  private theme = this.$store.state.theme.mode;

  get mode() {
    return this.$store.state.theme.mode;
  }

  toggleMode() {
    if (this.theme === "light") {
      this.theme = "dark";
    } else {
      this.theme = "light";
    }

    setTimeout(() => {
      this.$store.commit("theme/setMode", this.theme);

      if (this.$store.state.theme.mode == "dark") {
        this.$ga.event({
          eventCategory: "Light",
          eventAction: "OFF",
          eventLabel: "Światło zostało wyłączone"
        });
      } else {
        this.$ga.event({
          eventCategory: "Light",
          eventAction: "ON",
          eventLabel: "Światło zostało włączone"
        });
      }

      if ("vibrate" in navigator) {
        navigator.vibrate(10);
      }

      let audio = new Audio("/sfx/switch.mp3");
      audio.oncanplaythrough = () => {
        audio.play();
      };

      // if (this.$store.state.theme.mode == 'dark') {
      //   this.$toasted.show('Włączyłeś tryb ciemny. Możesz go wyłączyć w każdej chwili klikając włącznik na stronie o nas.', { duration: 2000 })
      // } else {
      //   this.$toasted.show('Wyłączyłeś tryb ciemny. Możesz go włączyć w każdej chwili klikając włącznik na stronie o nas.', { duration: 2000 })
      // }
    }, 250);
  }

  @Watch("mode")
  onModeChange(value: any) {
    this.theme = value;
  }
}
