






















import { Component, Vue } from 'nuxt-property-decorator'
@Component<OFooter>({})
export default class OFooter extends Vue {}
