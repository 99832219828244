











import { Component, Vue } from "nuxt-property-decorator";

@Component<MNavbarLanguage>({})
export default class MNavbarLanguage extends Vue {
  languages = [
    { code: "en", name: "English" },
    { code: "pl", name: "Polski" },
    { code: "no", name: "Norsk" },
    { code: "de", name: "Deutsch" }
  ];
}
