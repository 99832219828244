import Vue from 'vue'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_plugin_3457ba14 from 'nuxt_plugin_plugin_3457ba14' // Source: ./components/plugin.js (mode: 'all')
import nuxt_plugin_hotjar_3138789e from 'nuxt_plugin_hotjar_3138789e' // Source: ./hotjar.js (mode: 'client')
import nuxt_plugin_templatesplugin7610d586_d7dfcc22 from 'nuxt_plugin_templatesplugin7610d586_d7dfcc22' // Source: ./templates.plugin.7610d586.js (mode: 'client')
import nuxt_plugin_vuecurrencyinput_b5985792 from 'nuxt_plugin_vuecurrencyinput_b5985792' // Source: ./vue-currency-input.js (mode: 'all')
import nuxt_plugin_pluginutils_49a258af from 'nuxt_plugin_pluginutils_49a258af' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginrouting_206f763a from 'nuxt_plugin_pluginrouting_206f763a' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_0935af75 from 'nuxt_plugin_pluginmain_0935af75' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_toast_a08a488a from 'nuxt_plugin_toast_a08a488a' // Source: ./toast.js (mode: 'client')
import nuxt_plugin_pluginclient_5400f91c from 'nuxt_plugin_pluginclient_5400f91c' // Source: ./content/plugin.client.js (mode: 'client')
import nuxt_plugin_pluginserver_3bb8f4fa from 'nuxt_plugin_pluginserver_3bb8f4fa' // Source: ./content/plugin.server.js (mode: 'server')
import nuxt_plugin_workbox_a6191db0 from 'nuxt_plugin_workbox_a6191db0' // Source: ./workbox.js (mode: 'client')
import nuxt_plugin_metaplugin_2afd103b from 'nuxt_plugin_metaplugin_2afd103b' // Source: ./pwa/meta.plugin.js (mode: 'all')
import nuxt_plugin_iconplugin_2fa4b5af from 'nuxt_plugin_iconplugin_2fa4b5af' // Source: ./pwa/icon.plugin.js (mode: 'all')
import nuxt_plugin_axios_38eae1e8 from 'nuxt_plugin_axios_38eae1e8' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_moment_c71a37e8 from 'nuxt_plugin_moment_c71a37e8' // Source: ./moment.js (mode: 'all')
import nuxt_plugin_googleanalytics_4db1299a from 'nuxt_plugin_googleanalytics_4db1299a' // Source: ./google-analytics.js (mode: 'client')
import nuxt_plugin_nuxtplugin_9585397e from 'nuxt_plugin_nuxtplugin_9585397e' // Source: ../node_modules/vue-rellax/lib/nuxt-plugin (mode: 'client')
import nuxt_plugin_swiper_419e176c from 'nuxt_plugin_swiper_419e176c' // Source: ../plugins/swiper (mode: 'client')
import nuxt_plugin_touch_2330c1cb from 'nuxt_plugin_touch_2330c1cb' // Source: ../plugins/touch (mode: 'client')
import nuxt_plugin_localStorage_08604484 from 'nuxt_plugin_localStorage_08604484' // Source: ../plugins/localStorage (mode: 'client')
import nuxt_plugin_vueinfiniteloading_5f2806cd from 'nuxt_plugin_vueinfiniteloading_5f2806cd' // Source: ../plugins/vue-infinite-loading.ts (mode: 'client')
import nuxt_plugin_vueyoutubeembed_63f8020a from 'nuxt_plugin_vueyoutubeembed_63f8020a' // Source: ../plugins/vue-youtube-embed.ts (mode: 'client')
import nuxt_plugin_moment_57893d7e from 'nuxt_plugin_moment_57893d7e' // Source: ../plugins/moment.js (mode: 'all')
import nuxt_plugin_routerfocusreset_4332ce21 from 'nuxt_plugin_routerfocusreset_4332ce21' // Source: ../plugins/router-focus-reset.ts (mode: 'client')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  const registerModule = store.registerModule
  store.registerModule = (path, rawModule, options) => registerModule.call(store, path, rawModule, Object.assign({ preserveState: process.client }, options))

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"titleTemplate":function anonymous(t
) {
return (t !== "Imoli" ? `${t} — Imoli` : "Imoli")
},"title":"Imoli","meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":"Imoli to nowoczesna agencja interaktywna i software house. W naszym zespole pracują profesjonaliści, którzy z zaangażowaniem wykonują swoją pracę."},{"name":"author","content":"Imoli \u003Cbiuro@imoli.pl\u003E (https:\u002F\u002Fimoli.dev), Dawid Napora \u003Cdawid@napora.dev\u003E (https:\u002F\u002Fnapora.dev)"}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.ico"},{"hid":"gf-prefetch","rel":"dns-prefetch","href":"https:\u002F\u002Ffonts.gstatic.com\u002F"},{"hid":"gf-preconnect","rel":"preconnect","href":"https:\u002F\u002Ffonts.gstatic.com\u002F","crossorigin":""},{"hid":"gf-preload","rel":"preload","as":"style","href":"https:\u002F\u002Ffonts.googleapis.com\u002Fcss2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Red+Hat+Display:ital,wght@0,700;1,700&display=swap"}],"style":[],"script":[{"hid":"gf-script","innerHTML":"(function (){var l=document.createElement('link');l.rel=\"stylesheet\";l.href=\"https:\u002F\u002Ffonts.googleapis.com\u002Fcss2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Red+Hat+Display:ital,wght@0,700;1,700&display=swap\";document.querySelector(\"head\").appendChild(l);})();"}],"noscript":[{"hid":"gf-noscript","innerHTML":"\u003Clink rel=\"stylesheet\" href=\"https:\u002F\u002Ffonts.googleapis.com\u002Fcss2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Red+Hat+Display:ital,wght@0,700;1,700&display=swap\"\u003E"}],"__dangerouslyDisableSanitizersByTagID":{"gf-script":["innerHTML"],"gf-noscript":["innerHTML"]}},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_3457ba14 === 'function') {
    await nuxt_plugin_plugin_3457ba14(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_hotjar_3138789e === 'function') {
    await nuxt_plugin_hotjar_3138789e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_templatesplugin7610d586_d7dfcc22 === 'function') {
    await nuxt_plugin_templatesplugin7610d586_d7dfcc22(app.context, inject)
  }

  if (typeof nuxt_plugin_vuecurrencyinput_b5985792 === 'function') {
    await nuxt_plugin_vuecurrencyinput_b5985792(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_49a258af === 'function') {
    await nuxt_plugin_pluginutils_49a258af(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_206f763a === 'function') {
    await nuxt_plugin_pluginrouting_206f763a(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_0935af75 === 'function') {
    await nuxt_plugin_pluginmain_0935af75(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_toast_a08a488a === 'function') {
    await nuxt_plugin_toast_a08a488a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_pluginclient_5400f91c === 'function') {
    await nuxt_plugin_pluginclient_5400f91c(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_pluginserver_3bb8f4fa === 'function') {
    await nuxt_plugin_pluginserver_3bb8f4fa(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_workbox_a6191db0 === 'function') {
    await nuxt_plugin_workbox_a6191db0(app.context, inject)
  }

  if (typeof nuxt_plugin_metaplugin_2afd103b === 'function') {
    await nuxt_plugin_metaplugin_2afd103b(app.context, inject)
  }

  if (typeof nuxt_plugin_iconplugin_2fa4b5af === 'function') {
    await nuxt_plugin_iconplugin_2fa4b5af(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_38eae1e8 === 'function') {
    await nuxt_plugin_axios_38eae1e8(app.context, inject)
  }

  if (typeof nuxt_plugin_moment_c71a37e8 === 'function') {
    await nuxt_plugin_moment_c71a37e8(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_googleanalytics_4db1299a === 'function') {
    await nuxt_plugin_googleanalytics_4db1299a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_nuxtplugin_9585397e === 'function') {
    await nuxt_plugin_nuxtplugin_9585397e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_swiper_419e176c === 'function') {
    await nuxt_plugin_swiper_419e176c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_touch_2330c1cb === 'function') {
    await nuxt_plugin_touch_2330c1cb(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_localStorage_08604484 === 'function') {
    await nuxt_plugin_localStorage_08604484(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vueinfiniteloading_5f2806cd === 'function') {
    await nuxt_plugin_vueinfiniteloading_5f2806cd(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vueyoutubeembed_63f8020a === 'function') {
    await nuxt_plugin_vueyoutubeembed_63f8020a(app.context, inject)
  }

  if (typeof nuxt_plugin_moment_57893d7e === 'function') {
    await nuxt_plugin_moment_57893d7e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_routerfocusreset_4332ce21 === 'function') {
    await nuxt_plugin_routerfocusreset_4332ce21(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // If server-side, wait for async component to be resolved first
  if (process.server && ssrContext && ssrContext.url) {
    await new Promise((resolve, reject) => {
      router.push(ssrContext.url, resolve, () => {
        // navigated to a different route in router guard
        const unregister = router.afterEach(async (to, from, next) => {
          ssrContext.url = to.fullPath
          app.context.route = await getRouteData(to)
          app.context.params = to.params || {}
          app.context.query = to.query || {}
          unregister()
          resolve()
        })
      })
    })
  }

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
