import { MutationTree, GetterTree } from 'vuex'
import { State as RootState } from '.'
export interface State {
  mode: 'light' | 'dark';
}

export const state = (): State => ({
  mode: 'light'
})

export const mutations: MutationTree<State> = {
  switchMode(state: State) {
    state.mode == 'dark' ? state.mode = 'light' : state.mode = 'dark'
  },

  setMode(state: State, mode: State['mode']) {
    state.mode = mode
  }
}

export const getters: GetterTree<State, RootState> = {
  getMode(state: State) {
    return state.mode
  }
}
