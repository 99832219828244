import { render, staticRenderFns } from "./AppNavbar.vue?vue&type=template&id=23a0ef8c&lang=pug&"
import script from "./AppNavbar.vue?vue&type=script&lang=ts&"
export * from "./AppNavbar.vue?vue&type=script&lang=ts&"
import style0 from "./AppNavbar.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppNavbarLogo: require('/var/www/imoli.app/components/App/AppNavbar/AppNavbarLogo.vue').default,AppNavbarLanguage: require('/var/www/imoli.app/components/App/AppNavbar/AppNavbarLanguage.vue').default,AppLightSwitch: require('/var/www/imoli.app/components/App/AppLightSwitch.vue').default,AppNavbarMenu: require('/var/www/imoli.app/components/App/AppNavbar/AppNavbarMenu.vue').default,AppNavbarBrief: require('/var/www/imoli.app/components/App/AppNavbar/AppNavbarBrief.vue').default,AppNavbarHamburger: require('/var/www/imoli.app/components/App/AppNavbar/AppNavbarHamburger.vue').default})
