



















import { Component, Vue } from 'nuxt-property-decorator'

@Component<MFooterDecoration>({})
export default class MFooterDecoration extends Vue {}
