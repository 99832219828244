import { MutationTree } from 'vuex'

export interface State {
  remember: {
    dontInstall: boolean;
    cookiesAccepted: boolean;
  };
}

export const state = (): State => ({
  remember: {
    dontInstall: false,
    cookiesAccepted: false
  }
})

export const mutations: MutationTree<State> = {
  SET_DONT_INSTALL: (state, value) => state.remember.dontInstall = value,
  SET_COOKIES_ACCEPTED: (state, value) => state.remember.cookiesAccepted = value
}
