import { render, staticRenderFns } from "./AppShowreelModal.vue?vue&type=template&id=4cc26087&lang=pug&"
import script from "./AppShowreelModal.vue?vue&type=script&lang=ts&"
export * from "./AppShowreelModal.vue?vue&type=script&lang=ts&"
import style0 from "./AppShowreelModal.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppButton: require('/var/www/imoli.app/components/App/AppButton.vue').default})
