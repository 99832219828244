







import { Component, Vue } from 'nuxt-property-decorator'

@Component<MFooterPhone>({})
export default class MFooterPhone extends Vue {}
