








import { Component, Vue, Prop } from 'nuxt-property-decorator'

@Component<ANavbarHamburgerTrigger>({})
export default class ANavbarHamburgerTrigger extends Vue {
  @Prop({ default: false }) active!: boolean
}
